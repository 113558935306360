import React from "react";

const PopupForm = ({
  isOpen,
  onClose,
  onSubmit,
  newProject,
  setNewProject,
}) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content rounded-[32px] p-10">
        <div className="flex flex-col gap-1 pb-5">
          <p className="text-[#1D3557] font-semibold text-3xl">
            Create New Project
          </p>
          <p className="text-[#4E5969]">Create a new Project</p>
        </div>
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label htmlFor="name">Project Name:</label>
            <input className="outline-none"
              type="text"
              id="name"
              name="name"
              value={newProject.name}
              onChange={(e) =>
                setNewProject({ ...newProject, name: e.target.value })
              }
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Project Description:</label>
            <textarea
              className="h-52"
              id="description"
              name="description"
              value={newProject.description}
              onChange={(e) =>
                setNewProject({ ...newProject, description: e.target.value })
              }
            />
          </div>
          <div className="form-actions">
            <button
              className="border border-[#1D3557] text-[#1D3557] rounded"
              type="button"
              onClick={onClose}
            >
              Cancel
            </button>
            <button className="bg-[#111E32] text-white rounded" type="submit">
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PopupForm;
