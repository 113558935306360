import { CircleUserRound, Mail, Phone, Trash2 } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";

function UpdateProfile() {
  return (
    <div className="bg-[#E7E9EB] h-full">
      <div className="max-w-screen-lg mx-auto h-full">
        <div className="flex justify-between pt-5">
        <p className="text-[#111E32] text-3xl font-medium ">
          Update Profile
        </p>
        <Link to="/chat"
           
            className="back-button text-xs md:text-[16px]"
          >
            Back to Chat
          </Link>
        </div>

        <div className="flex justify-center items-center h-full">
          <div className="flex flex-col gap-6 p-10 w-full">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-[18px]">
                <div className="bg-white rounded-full size-12"></div>
                <div>
                  <p className="text-black text-xl font-semibold">
                    Johan Andrews
                  </p>
                  <p className="text-[#71717A] text-base">Admin</p>
                </div>
              </div>
              <div className="flex icon gap-2">
                <button className="border border-[rgba(0,_0,_0,_0.10)] rounded-md bg-white p-[10px] text-[#4B7DC3] text-sm font-medium flex items-center gap-[10px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M20.25 3.75H3.75C3.35218 3.75 2.97064 3.90804 2.68934 4.18934C2.40804 4.47064 2.25 4.85218 2.25 5.25V18.75C2.25 19.1478 2.40804 19.5294 2.68934 19.8107C2.97064 20.092 3.35218 20.25 3.75 20.25H20.25C20.6478 20.25 21.0294 20.092 21.3107 19.8107C21.592 19.5294 21.75 19.1478 21.75 18.75V5.25C21.75 4.85218 21.592 4.47064 21.3107 4.18934C21.0294 3.90804 20.6478 3.75 20.25 3.75ZM20.25 5.25V14.8828L17.8059 12.4397C17.6666 12.3004 17.5013 12.1898 17.3193 12.1144C17.1372 12.039 16.9422 12.0002 16.7452 12.0002C16.5481 12.0002 16.3531 12.039 16.1711 12.1144C15.989 12.1898 15.8237 12.3004 15.6844 12.4397L13.8094 14.3147L9.68438 10.1897C9.4031 9.9086 9.02172 9.7507 8.62406 9.7507C8.22641 9.7507 7.84503 9.9086 7.56375 10.1897L3.75 14.0034V5.25H20.25ZM3.75 16.125L8.625 11.25L16.125 18.75H3.75V16.125ZM20.25 18.75H18.2466L14.8716 15.375L16.7466 13.5L20.25 17.0044V18.75ZM13.5 9.375C13.5 9.1525 13.566 8.93499 13.6896 8.74998C13.8132 8.56498 13.9889 8.42078 14.1945 8.33564C14.4 8.25049 14.6262 8.22821 14.8445 8.27162C15.0627 8.31502 15.2632 8.42217 15.4205 8.5795C15.5778 8.73684 15.685 8.93729 15.7284 9.15552C15.7718 9.37375 15.7495 9.59995 15.6644 9.80552C15.5792 10.0111 15.435 10.1868 15.25 10.3104C15.065 10.434 14.8475 10.5 14.625 10.5C14.3266 10.5 14.0405 10.3815 13.8295 10.1705C13.6185 9.95952 13.5 9.67337 13.5 9.375Z"
                      fill="#4B7DC3"
                    />
                  </svg>
                  Change Picture
                </button>
                <button className="border border-[rgba(0,_0,_0,_0.10)] rounded-md bg-[#FFE8E9] p-[10px] text-[#F53F48] text-sm font-medium flex items-center gap-[10px]">
                  <Trash2 />
                  Delete Picture
                </button>
              </div>
            </div>
            <div className="flex flex-col items-start gap-2">
              <p className="text-lg font-medium">Personal Info</p>
              <p className="text-[#71717A] text-xs">
                Following information is public
              </p>
            </div>
            <div>
              <form className="flex flex-col gap-4">
                <div className="">
                  <label htmlFor="name">Profile Name</label>
                  <div className="flex items-center gap-[10px] bg-white border border-[#B8BCC2] rounded-md p-[10px] w-full md:w-1/2">
                    <CircleUserRound className="text-[#71717A]"/>
                    <input
                      className="outline-none w-full"
                      placeholder="Johan Andrews"
                      type="text"
                      id="name"
                      name="name"
                      required
                    />
                  </div>
                </div>
                <div className="">
                  <label htmlFor="description">Username</label>
                  <div className="flex items-center gap-[10px] bg-white border border-[#B8BCC2] rounded-md p-[10px] w-full md:w-1/2">
                    <Mail className="text-[#71717A]"/>
                    <input
                      className="outline-none w-full"
                      placeholder="Johan@gmail.com"
                      type="text"
                      id="name"
                      name="name"
                      required
                    />
                  </div>
                </div>
                <div className="">
                  <label htmlFor="description">Phone No</label>
                  <div className="flex items-center gap-[10px] bg-white border border-[#B8BCC2] rounded-md p-[10px] w-full md:w-1/2">
                    <Phone className="text-[#71717A]"/>
                    <input
                      className="outline-none w-full"
                      placeholder="Enter your Phone no"
                      type="text"
                      id="name"
                      name="name"
                      required
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="flex justify-end gap-2">
              <button
                className="border border-[#1D3557] text-[#1D3557] rounded p-[10px]"
                type="button"
              >
                Discard
              </button>
              <button className="bg-[#111E32] text-white rounded p-[10px]">
              Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateProfile;
