import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [groupedChatHistory, setGroupedChatHistory] = useState({
    today: [],
    previous7Days: [],
    previous30Days: [],
  });

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    setLoading(false);
  }, []);

  const login = async (provider, token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/${provider}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token })
      });
      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.error === "User not authorized. Please contact admin for access.") {
          setError(errorData.error);
          throw new Error(errorData.error);
        }
        throw new Error('Login failed');
      }
      const data = await response.json();
      const userData = { ...data, token, provider };
      setUser(userData);
      localStorage.setItem('user', JSON.stringify(userData));
      setError(null);
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
    setError(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, groupedChatHistory, setGroupedChatHistory, logout, loading, error, setError }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}