import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import DOMPurify from 'dompurify';

function ChatHistory() {
  const { userId } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [chatHistory, setChatHistory] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userName, setUserName] = useState('');


  useEffect(() => {
    fetchUserName();
    fetchChatHistory();
  }, [userId]);


  const fetchUserName = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/${userId}`, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
          'X-Auth-Provider': user.provider
        }
      });
      if (!response.ok) throw new Error('Failed to fetch user name');
      const data = await response.json();
      setUserName(data.name);
    } catch (error) {
      console.error('Error fetching user name:', error);
      setError('Failed to load user information.');
    }
  };

  
  useEffect(() => {
    fetchChatHistory();
  }, [userId]);

  const fetchChatHistory = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/${userId}/chat-history`, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
          'X-Auth-Provider': user.provider
        }
      });
      if (!response.ok) throw new Error('Failed to fetch chat history');
      const data = await response.json();
      setChatHistory(data);
    } catch (error) {
      console.error('Error fetching chat history:', error);
      setError('Failed to load chat history. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchConversationMessages = async (conversationId) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/conversationshistory/${conversationId}/messages`, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
          'X-Auth-Provider': user.provider
        }
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch conversation messages');
      }
      const data = await response.json();
      setMessages(data);
    } catch (error) {
      console.error('Error fetching conversation messages:', error);
      setError('Failed to load messages. Please try again.');
      setMessages([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectConversation = (conversation) => {
    setSelectedConversation(conversation);
    fetchConversationMessages(conversation.id);
  };

  const handleBackToUserManagement = () => {
    navigate('/user-management');
  };

  const renderMessageContent = (content, role) => {
    if (role === 'assistant') {
      return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />;
    }
    return <p>{content}</p>;
  };

  return (
    <div className="chat-history-page">
      <div className="chat-history-header">
      <h2>Chat History - {userName}</h2>
        <button onClick={handleBackToUserManagement} className="back-button">Back to User Management</button>
      </div>
      {isLoading && <p>Loading...</p>}
      {error && <p className="error-message">{error}</p>}
      {!isLoading && !error && (
        <div className="chat-history-content">
          <div className="chat-history-list">
            <h3>Conversations</h3>
            {chatHistory.length === 0 ? (
              <p>No conversations found.</p>
            ) : (
              <ul>
                {chatHistory.map((conversation) => (
                  <li 
                    key={conversation.id}
                    onClick={() => handleSelectConversation(conversation)}
                    className={selectedConversation?.id === conversation.id ? 'active' : ''}
                  >
                    {conversation.title || `Chat ${conversation.id}`}
                    <span className="chat-date">{new Date(conversation.created_at).toLocaleDateString()}</span>
                    <span className="message-count">Messages: {conversation.message_count}</span>
                  </li>
                ))}
              </ul>
            )}
          </div>
          {selectedConversation && (
            <div className="conversation-messages">
              <h3>{selectedConversation.title || `Chat ${selectedConversation.id}`}</h3>
              {messages.length === 0 ? (
                <p>No messages in this conversation.</p>
              ) : (
                messages.map((message, index) => (
                  <div key={index} className={`message ${message.role}`}>
                    <span className="role">{message.role === 'user' ? 'User' : 'AI'}</span>
                    {renderMessageContent(message.content, message.role)}
                  </div>
                ))
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ChatHistory;