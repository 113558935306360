import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import DOMPurify from 'dompurify';

function AllChatHistory() {
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { user } = useAuth();
  const navigate = useNavigate();

  const ITEMS_PER_PAGE = 10; // Number of conversations per page

  useEffect(() => {
    fetchAllConversations(currentPage);
  }, [currentPage]);

  const fetchAllConversations = async (page) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/conversations/all?page=${page}&limit=${ITEMS_PER_PAGE}`, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
          'X-Auth-Provider': user.provider
        }
      });
      if (!response.ok) throw new Error('Failed to fetch conversations');
      const data = await response.json();
      setConversations(data.conversations);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error('Error fetching conversations:', error);
      setError('Failed to load conversations. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchConversationMessages = async (conversationId) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/conversationshistory/${conversationId}/messages`, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
          'X-Auth-Provider': user.provider
        }
      });
      if (!response.ok) throw new Error('Failed to fetch conversation messages');
      const data = await response.json();
      setMessages(data);
    } catch (error) {
      console.error('Error fetching conversation messages:', error);
      setError('Failed to load messages. Please try again.');
      setMessages([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectConversation = (conversation) => {
    setSelectedConversation(conversation);
    fetchConversationMessages(conversation.id);
  };

  const handleBackToChat = () => {
    navigate('/chat');
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const renderMessageContent = (content, role) => {
    if (role === 'assistant') {
      return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />;
    }
    return <p>{content}</p>;
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="all-chat-history">
      <div className="all-chat-history-header">
        <h2>All Chat History</h2>
        <button onClick={handleBackToChat} className="back-button">Back to Chat</button>
      </div>
      {isLoading && <p>Loading...</p>}
      {error && <p className="error-message">{error}</p>}
      {!isLoading && !error && (
        <div className="all-chat-history-content">
          <div className="conversation-list">
            <h3>Conversations</h3>
            {conversations.length === 0 ? (
              <p>No conversations found.</p>
            ) : (
              <ul>
                {conversations.map((conversation) => (
                  <li 
                    key={conversation.id}
                    onClick={() => handleSelectConversation(conversation)}
                    className={selectedConversation?.id === conversation.id ? 'active' : ''}
                  >
                    <span className="conversation-date">{formatDate(conversation.created_at)}</span>
                    <span className="conversation-user">{conversation.user_name}</span>
                    <span className="conversation-engine">Engine: {conversation.engine}</span>
                    <span className="message-count">Messages: {conversation.message_count}</span>
                  </li>
                ))}
              </ul>
            )}
            <div className="pagination">
              {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                <button
                  key={page}
                  onClick={() => handlePageChange(page)}
                  className={currentPage === page ? 'active' : ''}
                >
                  {page}
                </button>
              ))}
            </div>
          </div>
          {selectedConversation && (
            <div className="conversation-messages">
              <h3>Conversation Details</h3>
              <p>Date: {formatDate(selectedConversation.created_at)}</p>
              <p>User: {selectedConversation.user_name}</p>
              <p>Engine: {selectedConversation.engine}</p>
              <h4>Messages:</h4>
              {messages.length === 0 ? (
                <p>No messages in this conversation.</p>
              ) : (
                messages.map((message, index) => (
                  <div key={index} className={`message ${message.role}`}>
                    <span className="message-role">{message.role === 'user' ? 'User' : 'AI'}</span>
                    {renderMessageContent(message.content, message.role)}
                  </div>
                ))
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default AllChatHistory;