import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { useMsal } from "@azure/msal-react";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import "../styles/Login.css";

function Login() {
  const { login, error, setError } = useAuth();
  const navigate = useNavigate();
  const { instance } = useMsal();

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      await login("google", credentialResponse.credential);
      navigate("/chat");
    } catch (error) {
      console.error("Google login failed:", error);
      // Error is already set in AuthContext
    }
  };

  const handleMicrosoftLogin = async () => {
    try {
      const response = await instance.loginPopup({
        scopes: ["user.read"],
        prompt: "select_account",
      });
      await login("microsoft", response.accessToken);
      navigate("/chat");
    } catch (error) {
      console.error("Microsoft login failed:", error);
      // Error is already set in AuthContext
    }
  };

  return (
    <div className="login-container flex flex-col md:flex-row">
      <div className="login-div">
        <h1 className="flex items-center text-[#1D3557] text-xl md:text-4xl xl:text-5xl font-light">
          Welcome to <span className="text-[#1D3557] text-xl md:text-4xl xl:text-[51px]"> Benchmark</span><span className="text-[#457B9D] text-xl md:text-4xl xl:text-[51px] font-bold">ai</span>
          <span className="text-[#E63946] text-xl md:text-4xl xl:text-[51px] font-bold">.</span>
        </h1>
        {error && <div className="error-message">{error}</div>}
        <div className="flex flex-col md:flex-row items-center gap-3">
          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onError={() => {
              console.log("Google Login Failed");
              setError("Google Login Failed");
            }}
            useOneTap
          />
          <button
            onClick={handleMicrosoftLogin}
            className="microsoft-login-button"
          >
            <img
              src="/microsoft-logo.png"
              alt="Microsoft logo"
              className="microsoft-logo"
            />
            Continue with Microsoft
          </button>
        </div>
      </div>
      <div className="login-imgdiv hidden lg:flex">
        <div className="img-div">
        <img src="/landingImage.png"/>
        </div>
      </div>
    </div>
  );
}

export default Login;
