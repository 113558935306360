import React, { useState, useRef, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Upload, File, Image, FileText, X, ZoomIn, ZoomOut, Maximize, Minimize ,FileSpreadsheet, Paperclip } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import * as XLSX from 'xlsx';

const ImagePreview = ({ url, onClose }) => {
  const [scale, setScale] = useState(1);

  const zoomIn = () => setScale(prev => Math.min(prev + 0.1, 3));
  const zoomOut = () => setScale(prev => Math.max(prev - 0.1, 0.1));

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Escape') {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);


  return ReactDOM.createPortal(
    <div className="preview-overlay" onClick={onClose}>
      <div className="preview-content" onClick={e => e.stopPropagation()}>
        <button className="preview-close" onClick={onClose}><X size={24} /></button>
        <div className="preview-image-container">
          <img 
            src={url} 
            alt="Preview" 
            style={{ transform: `scale(${scale})` }}
          />
        </div>
        <div className="preview-controls">
          <button onClick={zoomIn}><ZoomIn size={24} /></button>
          <button onClick={zoomOut}><ZoomOut size={24} /></button>
        </div>
      </div>
    </div>,
    document.body
  );
};

const PDFPreview = ({ url, onClose }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullscreen(false);
      }
    }
  };

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Escape') {
      if (isFullscreen) {
        document.exitFullscreen();
        setIsFullscreen(false);
      } else {
        onClose();
      }
    }
  }, [isFullscreen, onClose]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return ReactDOM.createPortal(
    <div className={`preview-overlay ${isFullscreen ? 'fullscreen' : ''}`} onClick={onClose}>
      <div className="preview-content" onClick={e => e.stopPropagation()}>
        <button className="preview-close" onClick={onClose}><X size={24} /></button>
        <iframe src={`${url}#toolbar=0`} title="PDF Preview" className="preview-pdf" />
        <div className="preview-controls">
          <button onClick={toggleFullscreen}>
            {isFullscreen ? <Minimize size={24} /> : <Maximize size={24} />}
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

const CSVPreview = ({ content, onClose }) => {
    const [isFullscreen, setIsFullscreen] = useState(false);
  
    const toggleFullscreen = () => {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
        setIsFullscreen(true);
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
          setIsFullscreen(false);
        }
      }
    };
  
    const handleKeyDown = useCallback((event) => {
      if (event.key === 'Escape') {
        if (isFullscreen) {
          document.exitFullscreen();
          setIsFullscreen(false);
        } else {
          onClose();
        }
      }
    }, [isFullscreen, onClose]);
  
    useEffect(() => {
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [handleKeyDown]);
  
    return ReactDOM.createPortal(
      <div className={`preview-overlay ${isFullscreen ? 'fullscreen' : ''}`} onClick={onClose}>
        <div className="preview-content" onClick={e => e.stopPropagation()}>
          <button className="preview-close" onClick={onClose}><X size={24} /></button>
          <pre className="preview-csv">{content}</pre>
          <div className="preview-controls">
            <button onClick={toggleFullscreen}>
              {isFullscreen ? <Minimize size={24} /> : <Maximize size={24} />}
            </button>
          </div>
        </div>
      </div>,
      document.body
    );
};

const ExcelPreview = ({ content, onClose }) => {
    const [isFullscreen, setIsFullscreen] = useState(false);
  
    const toggleFullscreen = () => {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
        setIsFullscreen(true);
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
          setIsFullscreen(false);
        }
      }
    };
  
    const handleKeyDown = useCallback((event) => {
      if (event.key === 'Escape') {
        if (isFullscreen) {
          document.exitFullscreen();
          setIsFullscreen(false);
        } else {
          onClose();
        }
      }
    }, [isFullscreen, onClose]);
  
    useEffect(() => {
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [handleKeyDown]);
  
    return ReactDOM.createPortal(
      <div className={`preview-overlay ${isFullscreen ? 'fullscreen' : ''}`} onClick={onClose}>
        <div className="preview-content" onClick={e => e.stopPropagation()}>
          <button className="preview-close" onClick={onClose}><X size={24} /></button>
          <pre className="preview-excel">{JSON.stringify(content, null, 2)}</pre>
          <div className="preview-controls">
            <button onClick={toggleFullscreen}>
              {isFullscreen ? <Minimize size={24} /> : <Maximize size={24} />}
            </button>
          </div>
        </div>
      </div>,
      document.body
    );
};

const FileUpload = ({ onFileUpload, conversationId, newChatStarted }) => {
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [error, setError] = useState(null);
    const [preview, setPreview] = useState(null);
    const [showPreview, setShowPreview] = useState(false);
    const fileInputRef = useRef(null);
    const { user } = useAuth();
  
    useEffect(() => {
      if (newChatStarted) {
        setPreview(null);
        setError(null);
        setUploadProgress(0);
        setUploading(false);
        setShowPreview(false);
      }
    }, [newChatStarted]);
  
    const handleFileChange = async (e) => {
      const file = e.target.files[0];
      if (!file || !conversationId) return;
  
      setUploading(true);
      setError(null);
      setUploadProgress(0);
  
      // Create preview
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview({ type: 'image', url: reader.result, name: file.name });
        };
        reader.readAsDataURL(file);
      } else if (file.type === 'application/pdf') {
        setPreview({ type: 'pdf', url: URL.createObjectURL(file), name: file.name });
      } 
      else if (file.type === 'text/csv' || file.name.endsWith('.csv')) {
        const reader = new FileReader();
        reader.onload = (event) => {
          setPreview({ type: 'csv', content: event.target.result, name: file.name });
        };
        reader.readAsText(file)
      } else if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || 
      file.type === 'application/vnd.ms-excel' ||
      file.name.endsWith('.xlsx') || 
      file.name.endsWith('.xls')) {
            const reader = new FileReader();
            reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);
            setPreview({ type: 'excel', content: jsonData, name: file.name });
            };
            reader.readAsArrayBuffer(file);
       }  else {
        setPreview({ type: 'file', name: file.name });
      }
  
      const formData = new FormData();
      formData.append('file', file);
      formData.append('conversationId', conversationId);
  
      const xhr = new XMLHttpRequest();
      xhr.open('POST', `${process.env.REACT_APP_API_URL}/api/upload`, true);
      xhr.setRequestHeader('Authorization', `Bearer ${user.token}`);
      xhr.setRequestHeader('X-Auth-Provider', user.provider);
  
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentCompleted = Math.round((event.loaded * 100) / event.total);
          setUploadProgress(percentCompleted);
        }
      };
  
      xhr.onload = function() {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);
          onFileUpload(response.fileId, file.name);
          setUploading(false);
        } else {
          setError('Upload failed. Please try again.');
          setUploading(false);
        }
      };
  
      xhr.onerror = function() {
        setError('Network error. Please try again.');
        setUploading(false);
      };
  
      xhr.send(formData);
    };
  
    const handleIconClick = () => {
      fileInputRef.current.click();
    };
  
    const handlePreviewClick = () => {
      if (preview) {
        setShowPreview(true);
      }
    };
  
    const closePreview = () => {
      setShowPreview(false);
    };
  
    return (
      <div className="file-upload-container">
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept=".txt,.pdf,.png,.jpg,.jpeg,.csv,.xlsx,.xls"
          style={{ display: 'none' }}
        />
        <button
          onClick={handleIconClick}
          className={`file-upload-button ${uploading ? 'uploading' : ''}`}
          disabled={uploading || !conversationId}
          title={!conversationId ? "Start a conversation before uploading" : "Upload a file"}
        >
          <Paperclip size={24} />
          {uploading && (
            <div className="upload-progress-overlay" style={{ width: `${uploadProgress}%` }}></div>
          )}
        </button>
        {uploading && <div className="upload-progress-text">{uploadProgress}%</div>}
        {error && <div className="upload-error">{error}</div>}
        {preview && (
          <div className="file-preview" onClick={handlePreviewClick}>
            {preview.type === 'image' ? (
              <img src={preview.url} alt="Preview" className="preview-image" />
            ) : (
              <div className="preview-file">
                {preview.type === 'pdf' ? (
                  <FileText size={24} />
                ) : preview.name.endsWith('.txt') ? (
                  <File size={24} />
                ) : (
                  <Image size={24} />
                )}
                <span className="preview-filename">{preview.name}</span>
              </div>
            )}
          </div>
        )}
          {showPreview && preview && (
          preview.type === 'image' ? (
            <ImagePreview url={preview.url} onClose={closePreview} />
          ) : preview.type === 'pdf' ? (
            <PDFPreview url={preview.url} onClose={closePreview} />
          ) : preview.type === 'csv' ? (
            <CSVPreview content={preview.content} onClose={closePreview} />
          ) : preview.type === 'excel' ? (
            <ExcelPreview content={preview.content} onClose={closePreview} />
          ) : null
        )}
      </div>
    );
  };
  
  export default FileUpload;